.site {
    & > #app-main-container,
    & > .spContainer {
        margin: 0 auto;

        @include respond-from($desktop) {
            max-width: 1440px;
        }
    }

    & > .spContainer {
        margin-top: 50px;
        padding: 0 3px;
    }
}

#welcome-message-wrapper {
    background-color: #fff;
    margin: 30px 0 0;
    padding: 15px 0;

    p {
      font-size: 15px;
    }
}

#kuff-legend {}

#kuff-legend-wrapper {
    margin-left: 0;
    margin-right: 0;
    margin-top: 20px;
    padding-left: 0;
    padding-right: 0;

    .legend-title {
        margin: 0 auto;
        width: 830px;
    }
}

#services-wrapper {
    color: #fff;
    margin-top: 40px;
    text-align: center;
}

#kuff-index-separator {
    height: 50px;
}

#kuff-legend-wrapper,
#welcome-message-wrapper,
#services-wrapper {
    @include text-color(#000);
}