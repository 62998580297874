
@mixin font-color($color) {
  color: $color;
}

$large-desktop: 1680;
$desktop: 1440;
$tablet: 1280;
$smartphone: 768;
$small-smartphone: 480;
$smaller-smartphone: 320;

$bps: (
    'smaller-smartphone': $smaller-smartphone,
    'small-smartphone': $small-smartphone,
    'smartphone': $smartphone,
    'tablet': $tablet,
    'desktop': $desktop
);

//Media queries
@mixin respond-to($point) {
    @if $point == large-desktop {
        @media only screen and (min-width: #{$desktop}px) {
            @content;
        }
    } @else if $point == desktop {
        @media only screen and (min-width: #{$tablet}px) {
            @content;
        }
    } @else if $point == tablet {
        @media only screen and (max-width: #{$tablet - 1}px) {
            @content;
        }
    } @else if $point == above-smartphone {
        @media only screen and (min-width: #{$smartphone + 1}px) {
            @content;
        }
    } @else if $point == smartphone {
        @media only screen and (max-width: #{$smartphone}px) {
            @content;
        }
    } @else if $point == small-smartphone {
        @media only screen and (max-width: #{$small-smartphone}px) {
            @content;
        }
    } @else if $point == smaller-smartphone {
        @media only screen and (max-width: #{$smaller-smartphone}px) {
            @content;
        }
    }
}

@mixin respond-from($point) {
    $min-width: (map-get($bps, $point) or $point);

    @media only screen and (min-width: #{$min-width}px)  {
        @content;
    }
}

@mixin respond-upTo($point) {
    $max-width: (map-get($bps, $point) or $point) - 1;

    @media only screen and (max-width: #{$max-width}px)  {
        @content;
    }
}

//Media queries
@mixin respond-to-between($point_1, $point_2) {
    $min-width: (map-get($bps, $point_1) or $point_1);
    $max-width: (map-get($bps, $point_2) or $point_2) - 1;

    @media only screen and (min-width: #{$min-width}px) and (max-width: #{$max-width}px) {
        @content;
    }
}

/* Source */
@mixin ellipsis-multiline($font-size, $line-height, $lines-to-show, $margin:"0") {
  $height-calc: $font-size*$line-height*$lines-to-show;
  display: block;
  display: -webkit-box;
  font-size: $font-size*1px;
  line-height: $line-height;
  max-height: ($height-calc + $margin)*1px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;

  p:not(:first-of-type) {
    display: none;
  }
}

/// Get a z-index value from a layer name
/// @access public
/// @param {String} $layer - Layer’s name
/// @return {Number}
/// @require $z-indexes
@function z($layer) {
    @return map-get($z-indexes, $layer);
}

@mixin text-color($color) {
    color: $color;
}

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
	$src: null;

	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
	);

	$formats: (
		otf: "opentype",
		ttf: "truetype"
	);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
	}

	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src;
	}
}

@mixin google-font($family) {
    @import url("http://fonts.googleapis.com/css?family=#{$family}");
}