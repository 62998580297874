.legend-wrapper {
    margin-left: 0;
    margin-right: 0;
    margin-top: 20px;
    padding-left: 0;
    padding-right: 0;

    .legend-title {
        margin: 0 auto;
        width: 830px;
    }
}

.tab-content {
    background-color: #fff;
    border: solid 1px #dedede;
    margin-bottom: 35px;
    padding: 25px;
}

.tab-content.content-focus {
    background-color: #f5f5f5;
    border: none;
}

.gray-container {
    background-color: #fff;
    border: solid 1px #d4d0d0;
    margin-bottom: 10px;
    padding: 10px;
}

.gray-container.dotted {
    border-color: #C7C7C7;
    border-style: dashed;
    border-width: 2px;
    box-sizing: border-box;
    display: table-cell;
    height: 143px;
    text-align: center;
    vertical-align: middle;
    width: 413px;
}

.gray-container a {
    color: #337ab7;
}

.reset-margin {
    margin: 0;
}

.reset-padding {
    padding: 0;
}

.block {
    display: block;
}

.navigateTo {
    cursor: pointer;
}

.ellipsis {
    display: -webkit-box;
    box-orient: vertical;
    line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
}
